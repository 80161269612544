import _ from "lodash";
import React, { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import { headerRoutes } from "../../../router";
import $ from "jquery";
import logo from "../../../assets/img/logo.png";

const languages = [
  { value: "en", label: "EN" },
  { value: "ua", label: "UA" },
];

const Header = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const source = urlParams.get('source');
  const buyer = urlParams.get('buyer');
  
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split("/"))}${source ? `?source=${source}` : ''}${buyer ? `&buyer=${buyer}` : ''}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
    }
  }, [pathname]);

  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
      return;
    }
    setLocale(lang);
  }, []);
  return (
    <div className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}>
      <div className={`${classes.container} container`}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/${lang}/${source ? `?source=${source}` : ''}${buyer ? `&buyer=${buyer}` : ''}`} onClick={() => setBurgerOpened(false)}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowRight}>
            <a href="mailto:offers@sinergia.network">
              <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"><path d="M36.1003 27.5501V10.4501C36.1003 8.8731 34.8272 7.6001 33.2502 7.6001H6.63125C5.05425 7.6001 3.78125 8.8731 3.78125 10.4501V27.5501C3.78125 29.1271 5.05425 30.4001 6.63125 30.4001H33.2502C34.8272 30.4001 36.1003 29.1271 36.1003 27.5501ZM33.6113 10.2411C34.2383 10.8681 33.8962 11.5141 33.5543 11.8371L25.8403 18.9051L33.2502 26.6191C33.4783 26.8851 33.6302 27.3031 33.3642 27.5881C33.1172 27.8921 32.5473 27.8731 32.3003 27.6831L23.9972 20.5961L19.9312 24.3011L15.8842 20.5961L7.58125 27.6831C7.33425 27.8731 6.76425 27.8921 6.51725 27.5881C6.25125 27.3031 6.40325 26.8851 6.63125 26.6191L14.0412 18.9051L6.32725 11.8371C5.98525 11.5141 5.64325 10.8681 6.27025 10.2411C6.89725 9.6141 7.54325 9.9181 8.07525 10.3741L19.9312 19.9501L31.8062 10.3741C32.3382 9.9181 32.9843 9.6141 33.6113 10.2411Z" fill="white"/></svg>
            </a>
            <a href="https://t.me/Nata_Sinergia" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none"><path d="M32.7909 5.78639C32.7909 5.78639 35.8665 4.58701 35.6092 7.49955C35.5245 8.69893 34.7558 12.8971 34.1573 17.4373L32.1069 30.8878C32.1069 30.8878 31.9359 32.8582 30.3977 33.201C28.8603 33.543 26.5533 32.0016 26.1258 31.6588C25.7838 31.4016 19.7181 27.5461 17.5822 25.662C16.9837 25.1474 16.2997 24.1198 17.6677 22.9204L26.638 14.353C27.6633 13.3254 28.6885 10.9267 24.4166 13.8392L12.4545 21.9776C12.4545 21.9776 11.0873 22.8349 8.52471 22.0638L2.97038 20.3499C2.97038 20.3499 0.919964 19.065 4.42309 17.7801C12.9675 13.7537 23.4769 9.6418 32.7893 5.78639H32.7909Z" fill="white"/></svg>
            </a>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;