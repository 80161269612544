import React from "react";
import classes from './MinDep.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/MinDep/image_01.png';
import image_02 from '../../../assets/img/HomePage/MinDep/image_02.png';
import image_03 from '../../../assets/img/HomePage/MinDep/image_03.png';

const cards = [
    {
        image: image_01,
        text: 'home_mindep_text_01',
    },
    {
        image: image_02,
        text: 'home_mindep_text_02',
    },
    {
        image: image_03,
        text: 'home_mindep_text_03',
    },
];

const MinDep = ({ setFormActive, setFormFrom }) => {
    const { t } = useTranslation();
    const join = () => {
      setFormActive(true);
      setFormFrom('Join');
    };
    return (
        <section className="mt container">
            <div className={classes.minDep}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className={classes.title}>
                                <Trans>
                                    {t(card.text)} 
                                </Trans>
                            </h4>
                            <div className={classes.line}></div>
                            <div className={classes.btnWrap}>
                                <div 
                                    className={classes.button}
                                    onClick={join}
                                >
                                    <span className="no-select">
                                        {t('home_button_join')}
                                    </span>
                                </div>
                            </div>
                            <img className={classes.image} src={card.image} alt=''/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default MinDep;
