import React from "react";
import classes from './BgTriangles.module.scss';

const BgTriangles = () => {
    let items = [];
    for (let i = 0; i < 50; i++) {
        items.push(i);
    }
    return (
        <div className={classes.bgTriangles}>
            {items.map((item) =>
                <div key={item} className={classes.gold}>
                    <div className={classes.rotate}>
                        <div className={classes.graphic}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BgTriangles;
