import React, { useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";
import portal from '../../../assets/img/HomePage/UnderBanner/portal.png';
import paint_01 from '../../../assets/img/HomePage/UnderBanner/paint_01.png';
import paint_02 from '../../../assets/img/HomePage/UnderBanner/paint_02.png';
import line from '../../../assets/img/HomePage/UnderBanner/line.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const cards = [
    // {
    //     title: 'home_underbanner_card_01_title',
    //     text: 'home_underbanner_card_01_text',
    // },
    {
        title: 'home_underbanner_card_02_title',
        text: 'home_underbanner_card_02_text',
    },
    {
        title: 'home_underbanner_card_03_title',
        text: 'home_underbanner_card_03_text',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    // const circleRef_01 = useRef(null);
    const circleRef_02 = useRef(null);
    const circleRef_03 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.fromTo(circleRef_03.current, {
                    opacity: 0,
                }, {
                    opacity: 1,
                    duration: 0.5,
                    delay: 0.4,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: 'bottom bottom',
                    }
                });
                gsap.fromTo(circleRef_02.current, {
                    opacity: 0,
                }, {
                    opacity: 1,
                    duration: 0.5,
                    delay: 0.6,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: 'bottom bottom',
                    }
                });
                // gsap.fromTo(circleRef_01.current, {
                //     opacity: 0,
                // }, {
                //     opacity: 1,
                //     duration: 0.5,
                //     delay: 0.8,
                //     scrollTrigger: {
                //         trigger: bodyRef.current,
                //         start: 'bottom bottom',
                //     }
                // });
            }, 300);
        }
    }, []);
    return (
        <section className={classes.underBannerWrap}>
            <img className={classes.paint_01} src={paint_01} alt=''/>
            <img className={classes.paint_02} src={paint_02} alt=''/>
            <div className="mt container">
                <div ref={bodyRef} className={classes.underBanner}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <h4 className={classes.cardTitle}>
                                    {t(card.title)}
                                </h4>
                                <p className={`${classes.cardText} font-18`}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classes.lineWrap}>
                        <img className={classes.lineImg} src={line} alt='' />
                        {/* <div ref={circleRef_01} className={`${classes.circle_01} ${classes.circle}`}></div> */}
                        <div ref={circleRef_02} className={`${classes.circle_02} ${classes.circle}`}></div>
                        <div ref={circleRef_03} className={`${classes.circle_03} ${classes.circle}`}></div>
                    </div>
                    <img className={classes.portal} src={portal} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
