import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/GlobalComponents/Header/Header";
import Footer from "../../../components/GlobalComponents/Footer/Footer";

const ClientAgreement = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header />
            <section className="container">
                <div className="docsPage">
                    <div className="docsViewTitle">
                        {t('agreement_title')}
                    </div>
                    <div className="docsViewBody">
                        <div className="docsViewBodyPar">
                            {t('agreement_text_01')}
                        </div>
                        <div className="docsViewBodyPar">
                            {t('agreement_text_02')}
                        </div>
                        <div className="docsViewBodyPar">
                            {t('agreement_text_03')}&nbsp;
                            <a href="http://www.sinergia.network">www.sinergia.network</a>.
                        </div>
                        <div className="docsViewBodyPar">
                            {t('agreement_text_04')}&nbsp;
                            <a href="http://www.sinergia.network">www.sinergia.network</a>.
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_05')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_06')}&nbsp;
                                <a href="http://www.sinergia.network">www.sinergia.network</a>.
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_07')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_08')}
                            </div>
                            <div className="docsViewBodyListDefis">
                                <div className="docsViewBodyPar">
                                    {t('agreement_text_09')}
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('agreement_text_10')}
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('agreement_text_11')}
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('agreement_text_12')}
                                </div>
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_13')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_14')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_15')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_16')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_17')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_18')}&nbsp;
                                <a href="http://www.sinergia.network">www.sinergia.network</a>.
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_19')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_20')}&nbsp;
                                <a href="http://www.sinergia.network">www.sinergia.network</a>;
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_21')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_22')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_23')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_24')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_25')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_26')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_27')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_28')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_29')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_30')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_31')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_32')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_33')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_34')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_35')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_36')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_37')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_38')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_39')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_40')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_41')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_42')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_43')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_44')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_45')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_46')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_47')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_48')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_49')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_50')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_51')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_52')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_53')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_54')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_55')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_56')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_57')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_58')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_59')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_60')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_61')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_62')}&nbsp;
                                <a href="http://www.sinergia.network">www.sinergia.network</a>
                                {t('agreement_text_63')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_64')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_65')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_66')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_67')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_68')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_69')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_70')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_71')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_72')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_73')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('agreement_text_74')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_75')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_76')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('agreement_text_77')}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ClientAgreement;
