import React, { useState } from "react";
import Banner from "../../components/HomePage/Banner/Banner";
import Blocks from "../../components/HomePage/Blocks/Blocks";
import Offers from "../../components/HomePage/Offers/Offers";
import NoRestriction from "../../components/HomePage/NoRestriction/NoRestriction";
import JoinTo from "../../components/HomePage/JoinTo/JoinTo";
import TalkAbout from "../../components/HomePage/TalkAbout/TalkAbout";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import MinDep from "../../components/HomePage/MinDep/MinDep";
import TakeBenefits from "../../components/HomePage/TakeBenefits/TakeBenefits";
import HowItWork from "../../components/HomePage/HowItWork/HowItWork";
import EarnTo from "../../components/HomePage/EarnTo/EarnTo";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import Form from "../../components/GlobalComponents/Form/Form";
import Buttons from "../../components/HomePage/Buttons/Buttons";

const HomePage = () => {
  const [ formActive, setFormActive ] = useState(false);
  const [ formFrom, setFormFrom ] = useState('');
  return (
    <div>
      <Form formActive={formActive} setFormActive={setFormActive} formFrom={formFrom} />
      <Header />
      <Banner setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <UnderBanner />
      <Offers /> 
      <MinDep setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <NoRestriction />
      <Blocks />
      <Buttons setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <JoinTo />
      <TakeBenefits setFormActive={setFormActive} setFormFrom={setFormFrom} />
      <HowItWork />
      <EarnTo />
      <TalkAbout />
      <Footer />
    </div>
  );
};

export default HomePage;
