import React, { useEffect, useRef } from "react";
import classes from './JoinTo.module.scss';
import { useTranslation } from "react-i18next";
import person_01 from '../../../assets/img/HomePage/JoinTo/person_01.png';
import person_02 from '../../../assets/img/HomePage/JoinTo/person_02.png';
import person_03 from '../../../assets/img/HomePage/JoinTo/person_03.png';
import person_04 from '../../../assets/img/HomePage/JoinTo/person_04.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const JoinTo = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: person_01,
            class: classes.card_01,
            text: 'home_join_to_li_01',
        },
        {
            image: person_02,
            class: classes.card_02,
            text: 'home_join_to_li_02',
        },
        {
            image: person_03,
            class: classes.card_03,
            text: 'home_join_to_li_03',
        },
        {
            image: person_04,
            class: classes.card_04,
            text: 'home_join_to_li_04',
        },
    ];
    return (
        <section className={classes.joinToWrap}>
            <div className="mt container">
                <div className={classes.joinTo}>
                    <div className={classes.content}>
                        <h2 className={classes.title}>
                            <span className={`${classes.titleText_01} font-32`}>
                                {t('home_join_to_text_01')}
                            </span>
                            <span className={`${classes.titleText_02} font-120`}>
                                Sinergia
                            </span>
                            <span className={`${classes.titleText_03} font-32`}>
                                {t('home_join_to_text_02')}
                            </span>
                        </h2>
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={`${classes.card} ${card.class}`}>
                                <img className={classes.cardPerson} src={card.image} alt=''/>
                                <div className={classes.cardContent}>
                                    <span className={`${classes.cardContentText} font-18`}>
                                        {t(card.text)}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoinTo;
