import React from "react";
import classes from './TakeBenefits.module.scss';
import { useTranslation } from "react-i18next";

const TakeBenefits = ({ setFormActive, setFormFrom }) => {
    const { t } = useTranslation();
    const sellTraffic = () => {
      setFormActive(true);
      setFormFrom('Sell Traffic');
    };
    return (
        <section className="mt container">
            <div className={classes.takeBenefitsWrap}>
                <div className={classes.takeBenefits}>
                    <div className={classes.content}>
                        <p className={classes.text_01}>
                            {t('home_take_benefits_text_01')}
                        </p>
                        <div className={classes.btnWrap}>
                            <div 
                                className={classes.button}
                                onClick={sellTraffic}
                            >
                                <span className="no-select">
                                    {t('home_button_sell_traff')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TakeBenefits;
