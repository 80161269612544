import React from "react";
import classes from './Buttons.module.scss';
import { useTranslation } from "react-i18next";

const Buttons = ({ setFormActive, setFormFrom }) => {
    const { t } = useTranslation();
    const buyTraffic = () => {
      setFormActive(true);
      setFormFrom('Buy Traffic');
    };
    const sellTraffic = () => {
      setFormActive(true);
      setFormFrom('Sell Traffic');
    };
    return (
        <section className="mt container">
            <div className={classes.buttons}>
                <div 
                    className={classes.button}
                    onClick={buyTraffic}
                >
                    <span className="no-select"> 
                        {t('home_button_buy_traff')}
                    </span>
                </div>
                <div 
                    className={classes.button}
                    onClick={sellTraffic}
                >
                    <span className="no-select">
                        {t('home_button_sell_traff')}
                    </span>
                </div>
            </div>
        </section>
    );
};

export default Buttons;
