import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/GlobalComponents/Footer/Footer";
import Header from "../../../components/GlobalComponents/Header/Header";

const TermsConditions = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header />
            <section className="container">
                <div class="docsPage">
                    <div class="docsViewTitle">
                        {t('terms_title')}
                    </div>
                    <div class="docsViewBody">
                        <div class="docsViewBodyPar">
                            {t('terms_text_01')}
                        </div>
                        <div class="docsViewBodyPar">
                            {t('terms_text_02')}
                        </div>
                        <div class="docsViewBodyPar">
                            {t('terms_text_03')}
                        </div>
                        <div class="docsViewBodyPar">
                            {t('terms_text_04')}
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_05')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_06')}
                                &nbsp;<a href="http://www.sinergia.network">www.sinergia.network</a>&nbsp;
                                {t('terms_text_07')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_08')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_09')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_10')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_11')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_12')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_13')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_14')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_15')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_16')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_17')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_18')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_19')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_20')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_21')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_22')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_23')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_24')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_25')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_26')}
                            </div>
                            <div class="docsViewBodyPar italic">
                                {t('terms_text_27')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_28')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_29')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_30')}
                            </div>
                            <div class="docsViewBodyPar italic">
                                {t('terms_text_31')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_32')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_33')}
                            </div>
                            <div class="docsViewBodyListDefis">
                                <div class="docsViewBodyPar">
                                    {t('terms_text_34')}
                                </div>
                                <div class="docsViewBodyPar">
                                    {t('terms_text_35')}
                                </div>
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_36')}
                            </div>
                            <div class="docsViewBodyPar italic">
                                {t('terms_text_37')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_38')}
                            </div>
                            <div class="docsViewBodyPar italic">
                                {t('terms_text_39')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_40')}
                            </div>
                            <div class="docsViewBodyPar italic">
                                {t('terms_text_41')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_42')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_43')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_44')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_45')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_46')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_47')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_48')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_49')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_50')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_51')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_52')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_53')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_54')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_55')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_56')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_57')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_58')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_59')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_60')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_61')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_62')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_63')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_64')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_65')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_66')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_67')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_68')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_69')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_70')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_71')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_72')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_73')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_74')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_75')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_76')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_77')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_78')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_79')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_80')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_81')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_82')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_83')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_84')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_85')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_86')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_87')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_88')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_89')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_90')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_91')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_92')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_93')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_94')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_95')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_96')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_97')}
                            </div>
                        </div>
                        <div class="docsViewBodyBlock">
                            <div class="docsViewBodyTitle">
                                {t('terms_text_98')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_99')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_100')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_101')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_102')}
                            </div>
                            <div class="docsViewBodyPar">
                                {t('terms_text_103')}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default TermsConditions;
