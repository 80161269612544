import HomePage from '../pages/HomePage/HomePage';
import PrivacyPolicy from '../pages/Docs/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../pages/Docs/TermsConditions/TermsConditions';
import ClientAgreement from '../pages/Docs/ClientAgreement/ClientAgreement';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/privacy-policy', element: PrivacyPolicy },
    { path: '/terms-conditions', element: TermsConditions },
    { path: '/client-agreement', element: ClientAgreement },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/privacy-policy', element: PrivacyPolicy },
    { path: '/:lang/terms-conditions', element: TermsConditions },
    { path: '/:lang/client-agreement', element: ClientAgreement },
];

export const headerRoutes = [
    // { id: 1, path: 'conditions', text: 'Trading Conditions' },
];
