import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import bg from '../../../assets/img/HomePage/Banner/bg.jpg';
import logo from '../../../assets/img/HomePage/Banner/logo.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Banner = ({ setFormActive, setFormFrom }) => {
  const { t } = useTranslation();
  const buyTraffic = () => {
    setFormActive(true);
    setFormFrom('Buy Traffic');
  };
  const sellTraffic = () => {
    setFormActive(true);
    setFormFrom('Sell Traffic');
  };
  return (
    <section className={classes.bannerWrap}>
      <div className={classes.bgWrap}>
        <img className={classes.bg} src={bg} alt=''/>
        <div className={classes.dark}></div>
      </div>
      <div className="container">
        <div className={classes.banner}>
          <div className={classes.content}>
            <h1 className={classes.header}>
              <div className={classes.title}>
                Sinergia
              </div>
              <div className={classes.subtitle}>
                {t('home_banner_text')}
              </div>
            </h1>
            <div className={classes.logoWrap}>
              <img className={classes.logo} src={logo} alt=''/>
            </div>
            <div className={classes.buttons}>
              <div 
                className={classes.button}
                onClick={buyTraffic}
              >
                <span className="no-select"> 
                  {t('home_button_buy_traff')}
                </span>
              </div>
              <div 
                className={classes.button}
                onClick={sellTraffic}
              >
                <span className="no-select">
                  {t('home_button_sell_traff')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
