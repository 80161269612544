import React from "react";
import classes from './Blocks.module.scss';
import { useTranslation } from "react-i18next";
import bg from '../../../assets/img/HomePage/Blocks/bg.png';

const Blocks = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.blocksWrap}>
            <img className={classes.bg} src={bg} alt=''/>
            <div className="mt container">
                <div className={classes.blocks}>
                    <div className={[classes.block, 'font-23'].join(' ')}>
                        <p>
                            {t('home_blocks_01_text_01')}
                        </p>
                        <p>
                            {t('home_blocks_01_text_02')}
                        </p>
                        <p>
                            {t('home_blocks_01_text_03')}
                        </p>
                        <p>
                            {t('home_blocks_01_text_04')}
                        </p>
                    </div>
                    <div className={[classes.block, 'font-23'].join(' ')}>
                        <p>
                            {t('home_blocks_02_text_01')}
                        </p>
                        <p>
                            {t('home_blocks_02_text_02')}
                        </p>
                        <p>
                            {t('home_blocks_02_text_03')}
                        </p>
                        <p>
                            {t('home_blocks_02_text_04')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Blocks;
