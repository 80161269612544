import React from "react";
import classes from './TalkAbout.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/TalkAbout/icon_01_01.png';
import icon_02 from '../../../assets/img/HomePage/TalkAbout/icon_01_02.png';
import icon_03 from '../../../assets/img/HomePage/TalkAbout/icon_01_03.png';
import icon_04 from '../../../assets/img/HomePage/TalkAbout/icon_01_04.png';
import icon_05 from '../../../assets/img/HomePage/TalkAbout/icon_01_05.png';
import icon_06 from '../../../assets/img/HomePage/TalkAbout/icon_01_06.png';
import icon_07 from '../../../assets/img/HomePage/TalkAbout/icon_01_07.png';
import icon_08 from '../../../assets/img/HomePage/TalkAbout/icon_02_01.png';
import icon_09 from '../../../assets/img/HomePage/TalkAbout/icon_02_02.png';
import icon_10 from '../../../assets/img/HomePage/TalkAbout/icon_02_03.png';
import icon_11 from '../../../assets/img/HomePage/TalkAbout/icon_02_04.png';
import icon_12 from '../../../assets/img/HomePage/TalkAbout/icon_03_01.png';
import icon_13 from '../../../assets/img/HomePage/TalkAbout/icon_03_02.png';
import icon_14 from '../../../assets/img/HomePage/TalkAbout/icon_03_03.png';

const TalkAbout = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.talkAbout}>
                <h2 className={classes.title}>
                    {t('home_talk_about_us_title')}
                </h2>
                <div className={[classes.content, 'no-select'].join(' ')}>
                    <div className={`${classes.ellipse} ${classes.ellipse_big}`}>
                        <img src={icon_01} className={`${classes.brand} ${classes.brand_big} ${classes.brand_dell}`} alt=""/>
                        <a href="https://piratecpa.net/coupon/otzyvy-sinergia/" className={`${classes.brand} ${classes.brand_big} ${classes.brand_pirate}`} target="_blank" rel="noreferrer">
                            <img src={icon_02} alt=""/>
                        </a>
                        <img src={icon_03} className={`${classes.brand} ${classes.brand_big} ${classes.brand_amazon}`} alt=""/>
                        <img src={icon_04} className={`${classes.brand} ${classes.brand_big} ${classes.brand_sony}`} alt=""/>
                        <img src={icon_05} className={`${classes.brand} ${classes.brand_big} ${classes.brand_nestle}`} alt=""/>
                        <img src={icon_06} className={`${classes.brand} ${classes.brand_big} ${classes.brand_coca}`} alt=""/>
                        <a href="https://www.affpaying.com" className={`${classes.brand} ${classes.brand_big} ${classes.brand_aff_paying} ${classes.nb_aff_paying}`} target="_blank" rel="noreferrer">
                            <img src={icon_07} alt=""/>
                        </a>
                    </div>
                    <div className={`${classes.ellipse} ${classes.ellipse_medium}`}>
                        <img src={icon_08} className={`${classes.brand} ${classes.brand_medium} ${classes.brand_samsung}`} alt=""/>
                        <img src={icon_09} className={`${classes.brand} ${classes.brand_medium} ${classes.brand_gap}`} alt=""/>
                        <img src={icon_10} className={`${classes.brand} ${classes.brand_medium} ${classes.brand_lenovo}`} alt=""/>
                        <img src={icon_11} className={`${classes.brand} ${classes.brand_medium} ${classes.brand_intel}`} alt=""/>
                    </div>
                    <div className={`${classes.ellipse} ${classes.ellipse_small}`}>
                        <img src={icon_12} className={`${classes.brand} ${classes.brand_small} ${classes.brand_ebay}`} alt=""/>
                        <img src={icon_13} className={`${classes.brand} ${classes.brand_small} ${classes.brand_geforse}`} alt=""/>
                        <a href="https://www.affplus.com" className={`${classes.brand} ${classes.brand_small} ${classes.brand_aff_plus} ${classes.nb_aff_plus}`} target="_blank" rel="noreferrer">
                            <img src={icon_14} alt=""/>
                        </a>
                    </div>
                </div>
                <div className={classes.mob_content}>
                    <a href="https://www.affplus.com" target="_blank" rel="noreferrer">
                        <img src={icon_14} alt=""/>
                    </a>
                    <a href="https://www.affpaying.com" target="_blank" rel="noreferrer">
                        <img src={icon_07} alt=""/>
                    </a>
                    <a href="https://piratecpa.net/coupon/otzyvy-sinergia/" target="_blank" rel="noreferrer">
                        <img src={icon_02} alt=""/>
                    </a>
                    <img src={icon_01} alt=""/>
                    <img src={icon_03} alt=""/>
                    <img src={icon_04} alt=""/>
                    <img src={icon_05} alt=""/>
                    <img src={icon_06} alt=""/>
                    <img src={icon_08} alt=""/>
                    <img src={icon_09} alt=""/>
                    <img src={icon_10} alt=""/>
                    <img src={icon_11} alt=""/>
                    <img src={icon_12} alt=""/>
                    <img src={icon_13} alt=""/>
                </div>
            </div>
        </section>
    );
};

export default TalkAbout;
