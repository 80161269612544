import React from "react";
import classes from './HowItWork.module.scss';
import { Trans, useTranslation } from "react-i18next";
import hand from '../../../assets/img/HomePage/HowItWork/hand.png';
import bg from '../../../assets/img/HomePage/HowItWork/bg.png';

const cards = [
    {
        text: 'home_how_it_work_card_text_01',
    },
    {
        text: 'home_how_it_work_card_text_02',
    },
    {
        text: 'home_how_it_work_card_text_03',
    },
    {
        text: 'home_how_it_work_card_text_04',
    },
];

const HowItWork = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.howItWorkWrap}>
            <img className={classes.bg} src={bg} alt=''/>
            <img className={classes.hand} src={hand} alt=''/>
            <div className="mt container">
                <div className={classes.howItWork}>
                    <div className={classes.content}>
                        <h2 className={classes.title}>
                            {t('home_how_it_work_title')}
                        </h2>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <div className={classes.cardNum}>
                                        #0{index + 1}
                                    </div>
                                    <div className={classes.cardLine}></div>
                                    <p className={`${classes.cardText} font-18`}>
                                        <Trans>
                                            {t(card.text)}
                                        </Trans>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWork;
