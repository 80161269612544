import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/GlobalComponents/Footer/Footer";
import Header from "../../../components/GlobalComponents/Header/Header";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header />
            <section className="container">
                <div className="docsPage">
                    <div className="docsViewTitle">
                        {t('privacy_title')}
                    </div>
                    <div className="docsViewBody">
                        <div className="docsViewBodyPar">
                            {t('privacy_text_01')}
                        </div>
                        <div className="docsViewBodyPar">
                            {t('privacy_text_02')}&nbsp;
                            <a href="mailto:info@sinergia.network">info@sinergia.network</a>.
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_03')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_04')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_05')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_06')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_07')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_08')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_09')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_10')} 
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_11')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_12')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_13')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_14')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_15')} 
                            </div>
                            <div className="docsViewBodyListDefis">
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_16')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_17')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_18')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_19')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_20')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_21')} 
                                </div>
                                <div className="docsViewBodyPar">
                                    {t('privacy_text_22')} 
                                </div>
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_23')} 
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_24')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_25')}&nbsp;
                                <a href="mailto:info@sinergia.network">info@sinergia.network</a>.
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_26')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_27')}&nbsp;
                                <a href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>
                                &nbsp;{t('privacy_text_28')}&nbsp;
                                <a href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>
                                &nbsp;{t('privacy_text_29')}&nbsp;
                                <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>
                                &nbsp;{t('privacy_text_30')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_31')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_32')}
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_33')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_34')}&nbsp;
                                <a href="mailto:info@sinergia.network">info@sinergia.network</a>.
                            </div>
                        </div>
                        <div className="docsViewBodyBlock">
                            <div className="docsViewBodyTitle">
                                {t('privacy_text_35')}
                            </div>
                            <div className="docsViewBodyPar">
                                {t('privacy_text_36')}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
