import React, { useRef } from "react";
import classes from './Offers.module.scss';
import { useTranslation } from "react-i18next";
import person_01 from '../../../assets/img/HomePage/Offers/person_01.png';
import person_02 from '../../../assets/img/HomePage/Offers/person_02.png';
import person_03 from '../../../assets/img/HomePage/Offers/person_03.png';
import person_04 from '../../../assets/img/HomePage/Offers/person_04.png';
import paint from '../../../assets/img/HomePage/Offers/paint.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const UnderbannerCards = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            image: person_01,
            class: classes.card_01,
            text: 'home_button_more_1000_prop',
            link: 'https://t.me/Nata_Sinergia',
        },
        {
            ref: cardRef_02,
            image: person_02,
            class: classes.card_02,
            text: 'home_button_weekly_payments',
            link: 'https://t.me/vitaliy_sinergia',
        },
        {
            ref: cardRef_03,
            image: person_03,
            class: classes.card_03,
            text: 'home_button_loyalty_prog',
            link: 'https://t.me/Nata_Sinergia',
        },
        {
            ref: cardRef_04,
            image: person_04,
            class: classes.card_04,
            text: 'home_button_support',
            link: 'https://t.me/aliona_sinergia_crm',
        },
    ];
    return (
        <section className={classes.offersWrap}>
            <div className="mt container">
                <img className={classes.paint} src={paint} alt=''/>
                <h2 className={classes.title}>
                    {t('home_offers_title')}
                </h2>
                <div className={classes.offers}>
                    {cards.map((card, index) =>
                        <div 
                            ref={card.ref}
                            key={index} 
                            className={[classes.card, card.class].join(' ')}
                        >
                            <img className={classes.cardPerson} src={card.image} alt=''/>
                            <a 
                                className={classes.cardLink}
                                href={card.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="no-select">
                                    {t(card.text)}
                                </span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderbannerCards;
