import React from "react";
import classes from './EarnTo.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const EarnTo = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.earnTo}>
                <Marquee autoFill speed={150}>
                    <h4 className={[classes.line, 'no-select'].join(' ')}>
                        <Trans>
                            {t('home_earn_to_text')} &nbsp;&nbsp;
                        </Trans>
                    </h4>
                </Marquee>
            </div>
        </section>
    );
};

export default EarnTo;
